module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/locales","languages":["en","ru"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":52903549,"webvisor":true,"trackHash":true,"useCDN":true,"afterBody":true,"defer":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vlad Abramov Frontend Dev","short_name":"VA","start_url":"/","background_color":"#ffffff","theme_color":"#195B97","display":"minimal-ui","icon":"./static/images/icon.png","lang":"en","localize":[{"start_url":"/ru/","lang":"ru","name":"Влад Абрамов Фронтенд-разработчик","short_name":"Влад Абрамов","description":"Фронтенд-разработчик из Москвы."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0d55234ff53cbf56978c1784e7509339"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-20},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
