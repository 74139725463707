// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-features-blog-post-posts-template-js": () => import("./../../../src/features/blog/post/posts/template.js" /* webpackChunkName: "component---src-features-blog-post-posts-template-js" */),
  "component---src-features-blog-post-template-js": () => import("./../../../src/features/blog/post/template.js" /* webpackChunkName: "component---src-features-blog-post-template-js" */),
  "component---src-features-blog-tag-template-js": () => import("./../../../src/features/blog/tag/template.js" /* webpackChunkName: "component---src-features-blog-tag-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

